import classnames from 'classnames';
import { postMethod } from 'core-lib/utils/api';
import { customToast } from 'core-lib/utils/customToast';
import {
  emailValidation,
  phoneNumberValidation,
  requiredFieldValidation,
  validateOnlyAlphabets,
} from 'core-lib/utils/validation';
import Image from 'next/legacy/image';
import { useState } from 'react';
import SendSVG from 'src/common/icons/send_white.svg';
import useForm from 'src/components/common/hooks/use_form';
import s from './contact_us.module.scss';

export const ContactUs = () => {
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const submitFormApi = async (payload) => {
    setDisableSubmitButton(true);
    const { response, error }: any = await postMethod(
      'https://cst.parkplus.io/api/v2/cst/admin_dashboard/general_query/',
      payload
    );
    if (response) {
      customToast({
        variant: 'positivePriority',
        label: 'Thanks! We will get back to you soon.',
      });
    }
    if (error) {
      customToast({
        variant: 'criticalPriority',
        label: 'Oops! Something went wrong.',
      });
      setDisableSubmitButton(false);
    }
  };

  const validate = (formData, name = 'all') => {
    const form = { ...formData };
    if (name === 'all' || name === 'select') {
      form.select.err = requiredFieldValidation(form.select.input).errMsg;
    }
    if (name === 'all' || name === 'name') {
      form.name.err =
        requiredFieldValidation(form.name.input).errMsg ||
        validateOnlyAlphabets(form.name.input).errMsg;
    }
    if (name === 'all' || name === 'city') {
      form.city.err = requiredFieldValidation(form.city.input).errMsg;
    }
    if (name === 'all' || name === 'email') {
      form.email.err =
        requiredFieldValidation(form.email.input).errMsg ||
        emailValidation(form.email.input).errMsg;
    }
    if (name === 'all' || name === 'mobile') {
      form.mobile.err =
        requiredFieldValidation(form.mobile.input).errMsg ||
        phoneNumberValidation(form.mobile.input).errMsg;
    }

    return form;
  };

  const submitForm = (form) => {
    if (Object.keys(form).every((key) => form[key].err === '')) {
      // TODO: handle captcha validation here
      submitFormApi({
        question: form.select.input,
        name: form.name.input,
        email: form.email.input,
        city: form.city.input,
        contact_no: form.mobile.input,
        query: form.message.input,
      });
    }
  };

  const { form, handleChange, handleSubmit } = useForm(submitForm, validate, {
    select: {
      input: '',
      err: '',
    },
    name: {
      input: '',
      err: '',
    },
    email: {
      input: '',
      err: '',
    },
    city: {
      input: '',
      err: '',
    },
    mobile: {
      input: '',
      err: '',
    },
    message: {
      input: '',
      err: '',
    },
  });

  return (
    <div className={s.root}>
      <div className={classnames(s.container, 'cmn_container_no_padding')}>
        <div className={s.bg_img}>
          <Image
            src="/img/contact-patterns.png"
            layout="fill"
            objectFit="cover"
          />
        </div>
        <div className={s.content}>
          <h3 className={s.title}>Got a business query?</h3>
          <p className={s.subtitle}>
            Just leave your email with us and we’ll get back to you shortly!
          </p>
        </div>
        <form className={s.form}>
          <div className={s.select_wrapper}>
            <select
              id="option"
              className={s.select}
              value={form.select.input}
              onChange={(e) => handleChange('select', e.target.value)}
              required
            >
              <option className={s.option} value="" hidden>
                I am looking for
              </option>

              <option className={s.option} value="Access Control for Society">
                Access Control for Society
              </option>
              <option
                className={s.option}
                value="Parking Solution for Commercial Space"
              >
                Parking Solution for Commercial Space
              </option>
              <option className={s.option} value="FasTag Related Queries">
                FasTag Related Queries
              </option>
              <option className={s.option} value="Valet Service">
                Valet Service
              </option>
              {/* <option
                className={s.option}
                value="EV solution for Society/Corporate"
              >
                EV solution for Society/Corporate
              </option>
              <option className={s.option} value="Daily Car Cleaning Service">
                Daily Car Cleaning Service
              </option>
              <option
                className={s.option}
                value="Assistance with FASTag related queries"
              >
                Assistance with FASTag related queries
              </option>
              <option className={s.option} value="Help with Park+ app">
                Help with Park+ app
              </option>
              <option className={s.option} value="Job opportunity with Park+">
                Job opportunity with Park+
              </option> */}
            </select>
            {form.select.err && <div className={s.err}>*Required field</div>}
          </div>
          <div>
            <input
              className={s.input_box}
              type="text"
              placeholder="Your full name"
              value={form.name.input}
              onChange={(e) => handleChange('name', e.target.value)}
              required
            />
            {form.name.err && (
              <div className={s.err}>*Required field & Only Alphabets</div>
            )}
          </div>
          <div>
            <input
              className={s.input_box}
              type="email"
              placeholder="Your email address"
              value={form.email.input}
              onChange={(e) => handleChange('email', e.target.value)}
              required
            />
            {form.email.err && <div className={s.err}>*Invalid email</div>}
          </div>
          <div>
            <input
              className={s.input_box}
              type="number"
              placeholder="Your mobile number"
              value={form.mobile.input}
              onChange={(e) => {
                if (e.target.value.length < 11) {
                  handleChange('mobile', e.target.value);
                }
              }}
              required
            />
            {form.mobile.err && (
              <div className={s.err}>*Phone number should be 10 digit</div>
            )}
          </div>
          <div>
            <input
              className={s.input_box}
              type="text"
              placeholder="Your City"
              value={form.city.input}
              onChange={(e) => handleChange('city', e.target.value)}
              required
            />
            {form.city.err && <div className={s.err}>*Required field</div>}
          </div>
          <textarea
            className={s.text_area}
            placeholder="Your message"
            value={form.message.input}
            onChange={(e) => handleChange('message', e.target.value)}
          />
          <div className={s.action}>
            <button
              type="submit"
              className={classnames(s.btn, {
                [s.disabled_btn]: disableSubmitButton,
              })}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={disableSubmitButton}
            >
              Send
              <SendSVG className={s.icon} />
            </button>
          </div>
        </form>
        <div className={s.img}>
          <Image
            src="/img/contact-illustration.png"
            layout="fill"
            objectFit="cover"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
